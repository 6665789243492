.not-found {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 50px;
    font-family: sans-serif;
  }
  
  .not-found h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .not-found p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .not-found a {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
  }
  
  .not-found a:hover {
    background-color: #444;
  }
  