.main{
    margin-top: 12px;
    background-color: #3032347b;
    width: 85vw;
    height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 12px;
}

.title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 28px;
    margin-bottom: 16px;
    text-shadow: 2px 2px 6px black;
}

.inputLabel{
    font-size: 16px;
}

.urlInput{
    width: 40vw;
    height: 50px;
    background-color: rgb(80, 80, 80);
    outline: none;
    padding-left: 15px;
}
.pathInput{
    width: 40vw;
    height: 50px;
    background-color: rgb(80, 80, 80);
    outline: none;
    padding-left: 15px;
}

.inputContainers{
    display: flex;
    justify-content: space-between;
}


.app{
    display: flex;
    justify-content: center;
}

.generateBtn{
    position: relative;
    margin-top: 24px;
    align-self: center;
    width: 120px;
    height: 40px;
    min-height: 40px;
    background-color: rgb(50, 126, 225);
    margin-bottom: 6px;
    box-shadow: 2px 2px 6px black;
}

.generateBtn:hover{
    background-color: rgb(1, 84, 193);
}

.tableData{
    margin-top: 4px;
    margin-bottom: 6px;    
}

.loginPage{
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.google-btn{
    align-self: center;
    display: flex;
    position: relative;
    font-size: 22px;
    font-weight: 500;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.google-icon{
    padding: 0;
    margin-right: 6px;
}

.profilePic{
    display: flex;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}

.userName{
    margin-left: 4px;
    font-size: 16px;
    font-weight: 600;
}

.pathForm{
    display: flex;
    position: relative;
}

.checkButton{
    cursor: pointer;
    position: absolute;
    right: 8px;
    align-self: center;
    border-radius: 24px;
    padding: 0px 16px;
    font-size: 16px;
    font-weight: 500;
    color: #1173d5;
}

.checkButton:hover{
    background-color: #1173d51c;
}

.listTitle{
    text-shadow: 2px 2px 6px black;
}

.disable{
    display: none;
}


@media screen and (max-width: 767px) {
    .main{
        width: 96vw;
        height: 95vh;
    }
    .userName{
        display: none;
    }
    .inputContainers{
        display: block;
        /* justify-content: space-between; */
    }
    .urlInput{
        width: 100%;
    }
    .pathInput{
        width: 100%;
    }

}